import { useEffect, useState } from 'react';
import Link from 'next/link';
import { useTranslations } from 'next-intl';

import RoadMap from 'utils/breadcrumbsOverflow';

import useStyles from './styles';

// const getBreadcrumbsPath = ({ breadcrumbs, title, path, index }) => {
//   const redirectionPath = Object.values(breadcrumbs)
//     .slice(1, index + 1)
//     .join('/');

//   return title === 'Home'
//     ? '/'
//     : typeof roadMap === 'string'
//     ? path
//     : redirectionPath;
// };

const getBreadcrumbs = roadMap => {
  let map;
  let moreStaticPaths = {};
  if (typeof roadMap === 'string') {
    map = roadMap.split('.');
  } else {
    map = roadMap.defaultPath.split('.');
    moreStaticPaths = roadMap.morePaths;
  }

  let state;
  const mapWay = map.reduce((acc, item) => {
    if (!state) {
      state = RoadMap[item].children;
      acc[RoadMap[item].title] = RoadMap[item].path;
    } else {
      acc[state[item].title] = state[item].path;
      state = state[item].children;
    }
    return acc;
  }, {});
  return { ...mapWay, ...moreStaticPaths };
};

const Breadcrumbs = ({ roadMap, page }) => {
  const t = useTranslations();
  const [breadcrumbs, setBreadcrumbs] = useState(getBreadcrumbs(roadMap));

  useEffect(() => {
    if (!roadMap) return;
    setBreadcrumbs(getBreadcrumbs(roadMap));
  }, [roadMap]);

  const styles = useStyles({ page });

  if (!breadcrumbs) {
    return null;
  }

  const paths = Object.entries(breadcrumbs);

  return (
    <nav aria-label="breadcrumbs">
      <ul className={styles.breadcrumb}>
        {paths.map(([title, path], index) => {
          const redirectionPath = Object.values(breadcrumbs)
            .slice(1, index + 1)
            .join('/');

          return (
            <li key={path}>
              {index !== paths.length - 1 ? (
                <Link
                  href={
                    title === 'Home'
                      ? '/'
                      : typeof roadMap === 'string'
                      ? path
                      : redirectionPath
                  }
                >
                  <a>
                    <span>{t(title)}</span>
                  </a>
                </Link>
              ) : (
                <span>{t(title)}</span>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
export default Breadcrumbs;
